.formBox[data-v-50b4457a] {
  position: absolute;
  top: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}
.formTitleBox[data-v-50b4457a] {
  width: calc(100% - 24px);
  border-bottom: 1px solid #bbbbbb;
  padding: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.leftBack[data-v-50b4457a] {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.backBox[data-v-50b4457a] {
  position: relative;
  line-height: 28px;
}
.backBox[data-v-50b4457a]:after {
  content: "";
  position: absolute;
  width: 1px;
  height: 16px;
  right: -20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background-color: #dcdfe6;
}
.rightBtn[data-v-50b4457a] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.formContentBox[data-v-50b4457a] {
  padding: 12px;
  height: calc(100% - 94px);
}
.formMain[data-v-50b4457a] {
  width: 100%;
  height: 100%;
  overflow: auto;
  height: calc(100% - 28px);
}
.iframeBox[data-v-50b4457a] {
  width: 100%;
  height: 100%;
}
.iframe[data-v-50b4457a] {
  width: 100%;
  height: 100%;
}